import {PlaceWithTicketInfo, getEventTicketLimitPerOrder} from '@wix/wix-events-commons-statics'
import {createAsyncAction} from '../../services/redux-toolkit'
import {isChooseSeatMode} from '../../selectors/navigation'
import {checkDonationError} from '../../services/donations'
import {DONATION_ERROR} from '../../types'
import {
  SEATING_ERROR,
  setPlaceDonationError,
  setSeatingError,
  setPlaceDonationErrors,
} from '../../reducers/seating/errors'
import {getPlaceQuantity, getPlacesInBasket} from '../../selectors/seating/places'
import {isPlaceArea, isPlaceInBasket, isPlaceReservableAsWhole} from '../../selectors/seating/place'
import {getSelectedTicketsQuantity, isTicketLimitReached} from '../../selectors/selected-tickets'

const donationErrors = (chooseSeatMode: boolean) =>
  chooseSeatMode ? {excludeErrors: [DONATION_ERROR.MINIMUM_NOT_REACHED]} : undefined

export const validateDonation = createAsyncAction<
  boolean,
  {placeId: string; ticket: wix.events.ticketing.Ticket; donation: string}
>('VALIDATE_DONATION', async ({placeId, ticket, donation}, {getState, dispatch}) => {
  const chooseSeatMode = isChooseSeatMode(getState())
  const error = checkDonationError(ticket, donation, donationErrors(chooseSeatMode))

  if (error) {
    dispatch(setPlaceDonationError({placeId, error}))
  }

  return !error
})

export const validateAllDonations = createAsyncAction<boolean, void>(
  'VALIDATE_ALL_DONATIONS',
  async (_, {getState, dispatch}) => {
    const state = getState()
    const chooseSeatMode = isChooseSeatMode(getState())
    const placesInBasket = getPlacesInBasket(state)

    const errors = placesInBasket
      .map(place => {
        const error = checkDonationError(place.ticket, place.donation, donationErrors(chooseSeatMode))
        if (error) {
          return {placeId: place.id, error}
        }
      })
      .filter(Boolean)

    dispatch(setPlaceDonationErrors(errors))

    return !errors.length
  },
)

export const validateTicketLimit = createAsyncAction<boolean, {place: PlaceWithTicketInfo}>(
  'VALIDATE_TICKET_LIMIT',
  async ({place}, {dispatch, getState}) => {
    const state = getState()
    const placeInBasket = isPlaceInBasket(place)
    const chooseSeatMode = isChooseSeatMode(state)
    const placeQuantity = getPlaceQuantity(state, place.id)
    const selectedTicketsQuantity = getSelectedTicketsQuantity(state)
    const ticketLimitReached = isTicketLimitReached(state)
    const eventTicketLimitPerOrder = getEventTicketLimitPerOrder(state.event)

    const wholeElementLimitReachedError =
      !placeInBasket &&
      isPlaceReservableAsWhole(place) &&
      placeQuantity === 0 &&
      eventTicketLimitPerOrder < (chooseSeatMode ? place.places.length : selectedTicketsQuantity + place.places.length)

    const ticketLimitReachedError = ticketLimitReached && (isPlaceArea(place) || !placeInBasket)

    if (wholeElementLimitReachedError || (!chooseSeatMode && ticketLimitReachedError)) {
      dispatch(setSeatingError(SEATING_ERROR.TICKET_LIMIT_REACHED))
      return false
    }
    return true
  },
)
