import {createSlice} from '@reduxjs/toolkit'
import {describeSeatingPlan} from '../../actions/seating/plan'
import {updatePlace, setPlaceQuantity, addPlaceDonation} from '../../actions/seating/place'

const placesSlice = createSlice({
  name: 'places',
  initialState: [],
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(describeSeatingPlan.fulfilled, (state, action) => action.payload.places)
      .addCase(setPlaceQuantity.fulfilled, (state, action) => action.payload.places)
      .addCase(addPlaceDonation.fulfilled, (state, action) => action.payload.places)
      .addCase(updatePlace.fulfilled, (state, action) => action.payload.places)
  },
})

export default placesSlice.reducer
